
import Component, { mixins } from 'vue-class-component';
import LoadMixin from '@/shared/mixins/load.mixin';
import * as msal from '@azure/msal-browser';
import { Route } from 'vue-router';
import ToastificationContent from '@/app/components/toastification/ToastificationContent.vue';
import b2cPolicies from '@/app/config/msal/policy.config';
import { loginRequest } from '@/app/config/msal/request.config';
import { tenantModule } from '@/store/modules/tenant/tenant.module';

@Component({
  components: {
    ToastificationContent,
  },
})
export default class MemberPassword extends mixins(LoadMixin) {
  email = '';

  token = '';

  organisationId = '';

  b2bResetPolicy: msal.PopupRequest = {
    ...b2cPolicies.authorities.signUpSignIn,
    ...loginRequest,
    // authority: b2cPolicies.authorities,
  };

  beforeRouteEnter(_to: any, from: Route, next: any) {
    const { org } = from.query;

    if (!org) {
      next('/');
    }

    tenantModule.setTenantId([org as string]);

    next();
  }

  mounted() {
    // const { token } = this.$route.query;
    // if (token) {
    //   this.token = token;
    // }
  }

  background() {
    // eslint-disable-next-line global-require
    return require('@/assets/images/pages/vuexy-login-bg.jpg');
  }

  async created() {
    this.token = this.$route.query.token as string;
    this.organisationId = this.$route.query.org as string;
    this.$msal.memberTokenSignUp(this.token).then((res: any) => {
      console.log(res);
    });
    // this.$msal
    //   .signIn({
    //     extraQueryParameters: { id_token_hint: this.token },
    //     authority: b2cPolicies.authorities.redeemInvitation.authority,
    //   })
    //   .catch((error: Error) => {
    //     this.$toast(
    //       {
    //         component: ToastificationContent,
    //         props: {
    //           title: error.name,
    //           icon: 'concierge-bell',
    //           text: error.message,
    //           variant: 'danger',
    //         },
    //       },
    //       {
    //         timeout: 8000,
    //       },
    //     );
    //   });

    // this.loading.close();
    // await auth.memberPasswordRedirect({ ...this.b2bResetPolicy, loginHint: 'nick@handycats.com' });
  }

  async sendPasswordReset() {
    this.start();
    this.stop();
    this.$vs.notification({
      title: 'Password reset sent',
      text: 'We have sent a password reset email to your email address, if you do not see in your inbox please check your spam folder',
      color: 'success',
      sticky: true,
    });
    this.$router.push({ name: 'login' });
  }
}
