<route>
{
  "meta": {
    "requiresAuth": false
  }
}
</route>

<template>
  <div class="member-invite">
    <MemberPassword />
  </div>
</template>

<script>
// @ is an alias to /src
import MemberPassword from '@/modules/organisation/components/MemberPassword.vue';

export default {
  name: 'member',
  components: {
    MemberPassword,
  },
  layout: 'AppFullPage',
};
</script>
